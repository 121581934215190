body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #07090B;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.sauce-enter {
  opacity: 0.01;
  transform: translate(0, 230px);
}

.sauce-enter.sauce-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 800ms ease-in;
}

.sauce-leave {
  opacity: 1;
  transform: translate(0, 0);
}

.sauce-leave.sauce-leave-active {
  opacity: 0.01;
  transition: all 800ms ease-in;
  transform: translate(0, -230px);
  width: 0px;
}

.sauce-appear {
  opacity: 0.01;
  transform: translate(0, 230px);
}

.sauce-appear.sauce-appear-active {
  opacity: 1;
  transition: all 800ms ease-in;
  transform: translate(0, 0);
}

::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: red; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}